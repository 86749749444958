<template>
  <v-card class="main pa-4">
    <div class="query">
      <v-select
        dense
        class="mr-2"
        v-model="query.domain"
        :items="items"
        item-text="name"
        item-value="domain"
        label="域名"
      ></v-select>
      <v-text-field
        dense
        class="mr-2"
        label="域名前缀"
        v-model="query.prefix"
      ></v-text-field>

      <v-text-field
        dense
        class="mr-2"
        label="数量"
        v-model="query.count"
      ></v-text-field>
      <v-btn color="primary" class="ml-2" @click="gendAddress()"
        >生成IPv6资源池</v-btn
      >
    </div>
    <div>
      <v-progress-linear v-if="loading" v-model="progress" height="25">
        <strong style="color: aliceblue">{{ progress }}%</strong>
      </v-progress-linear>
    </div>
    <div>
      <v-card flat v-if="!loading & status">
        <v-card-text>
          <v-row>
            <v-col sm="2">
              <v-spaceer></v-spaceer>
            </v-col>
            <v-col class="text-center">
              <v-icon style="color: green" size="80"
                >mdi-checkbox-marked-circle</v-icon
              >
              <div class="text-h5 black--text">{{ message }}</div>
            </v-col>
            <v-col sm="2">
              <v-spaceer></v-spaceer>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="2">
              <v-spaceer></v-spaceer>
            </v-col>
            <v-col class="text-center">
              <v-btn color="primary" class="ml-2" @click="goTo(0)"
                >查看明细</v-btn
              >
              <v-btn class="ml-2" @click="goTo(1)">返回页面</v-btn></v-col
            >
            <v-col sm="2"> <v-spaceer></v-spaceer></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialog: false,
      loading: false,
      query: {
        prefix: "2002:db8:0000:0000",
        domain: "",
        count: 1,
      },
      items: [],
      progress: 0,
      status: false,
    };
  },
  methods: {
    gendAddress() {
      this.loading = true;
      this.progress = 0;
      this.$axios
        .get(
          `/trunkserver/assets/gen-ipv6-addrs-main/?domain=${this.query.domain}&prefix=${this.query.prefix}&count=${this.query.count}`
        )
        .then((res) => {
          console.log(res);
          if (res.data && res.data == 100) {
            this.status = true;
            this.loading = false;
            this.message = "生成成功";
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      var id = setInterval(() => {
        this.getProgress(id);
      }, 1000);
    },
    getProgress(id) {
      if (this.loading) {
        this.$axios
          .get(`/trunkserver/assets/gen-ipv6-addrs-progress/`)
          .then((res) => {
            this.progress = res.data;
            if (res.data == 100) {
              clearInterval(id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goTo(index) {
      if (index == 0) {
        this.$router.push({
          path: "/domain/address_pool",
        });
      } else {
        this.$router.push({
          path: "/domain/rules",
        });
      }
    },
    getDomainList() {
      this.$axios
        .get(`/trunkserver/assets/entity/?page=1&&size=100000`)
        .then((res) => {
          console.log(res);
          if (res.data.results && res.data.results.length > 0) {
            this.items = res.data.results;
          }
          console.log(this.items);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getDomainList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.query {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
</style>
